import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  Confirm,
  ConfirmOption,
  LocationRemove,
  ModalService,
  UtilityService,
} from '@cloud-and-service/portal-lib';
import { firstValueFrom } from 'rxjs';
import { IdpContract } from 'src/app/models/idpContract';
import { RedirectType } from 'src/app/models/redirect-type';
import { CallBackService } from 'src/app/services/callback.service';

@Component({
  selector: 'app-idp-user',
  templateUrl: './idp-user.component.html',
  styleUrls: [],
})
export class IdpUserComponent implements OnInit {
  textMessage: string = '';
  showMessage: boolean = false;
  isErrorMessage: boolean = false;
  isGeneralError: boolean = false;
  showUserCredentials: boolean = true;
  dialogType: RedirectType = RedirectType.assignIdpUser;

  username?: string;
  password?: string;

  popup: boolean = false;

  flowDetails: IdpContract = {} as IdpContract;

  // RemoveContract
  locationRemove: LocationRemove[] = [];
  resetTimer: any = null;
  showRemoveContract: boolean = false;
  selectedContract: LocationRemove[] = [];

  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private callBackSvc: CallBackService,
    private authService: AuthService,
    private modalService: ModalService,
    private router: Router
  ) {}

  public get redirectType(): typeof RedirectType {
    return RedirectType;
  }

  setResetTimer(seconds: number) {
    if (this.resetTimer !== null) {
      clearTimeout(this.resetTimer);
    }

    if (seconds > 0) {
      this.resetTimer = setTimeout(() => {
        this.authService.logout();
      }, seconds * 1000);
    }
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.queryParams.pipe());
    if (location.pathname.toLowerCase().indexOf('addcontract') != -1) {
      this.dialogType = RedirectType.addContract;
    } else if (location.pathname.toLowerCase().indexOf('removecontract') != -1) {
      this.dialogType = RedirectType.removeContract;
    }

    if (this.utility.isEmpty(params['code'])) {
      this.clearLocalStore();

      // from popup.
      if (!this.utility.isEmpty(params['popup']) && params['popup'] === '1') {
        localStorage.setItem('acPopup', 'true');
        this.popup = true;
      }

      if (this.dialogType == RedirectType.removeContract) {
        await this.onProcessContract('mitid');
      }
    } else {
      this.flowDetails.code = params['code'];

      this.popup = localStorage.getItem('acPopup') === 'true';
      this.flowDetails.username = localStorage.getItem('acUsername');
      this.flowDetails.password = localStorage.getItem('acPassword');
      this.flowDetails.service = localStorage.getItem('acService');
      this.flowDetails.prefix = localStorage.getItem('prefix');
      if (
        this.flowDetails.prefix === undefined ||
        this.flowDetails.prefix === null ||
        this.flowDetails.prefix === 'undefined'
      ) {
        this.flowDetails.prefix = '';
      }

      if (this.dialogType === RedirectType.assignIdpUser) {
        await this.callBackSvc
          .assignIdpUser(this.flowDetails)
          .catch((error: any) => {
            this.isGeneralError = true;
          })
          .then((value: boolean | void) => {
            if (this.isGeneralError) {
              this.displayMessage('Fejl under tilknytning af bruger hos MitID.', true);
            } else if (value === undefined || value === false) {
              this.displayMessage('Fejl under tilknytning af bruger.', true);
            } else {
              this.displayMessage('Tilknytning er udført', false);
            }
          })
          .finally(() => {
            this.clearLocalStore();
          });
      } else if (this.dialogType === RedirectType.addContract) {
        await this.callBackSvc
          .addContract(this.flowDetails)
          .catch(() => {
            this.displayMessage('Fejl under tilføjelse af forbrugssted.', true);
          })
          .then((value: boolean | void) => {
            if (value === undefined || value === false) {
              this.displayMessage('Fejl under tilføjelse af forbrugssted.', true);
            } else {
              this.displayMessage('Tilføjelse er udført.', false);
            }
          })
          .finally(() => {
            this.clearLocalStore();
          });
      } else if (this.dialogType === RedirectType.removeContract) {
        await this.callBackSvc
          .getIdpContractsByCode(this.flowDetails)
          .then(async (result: LocationRemove[]) => {
            this.locationRemove = result;
            console.log('result', this.locationRemove);

            if (this.utility.isEmpty(this.locationRemove)) {
              this.displayMessage('Ingen forbrugssteder tilknyttet brugerens MitID', false);
            } else {
              this.showRemoveContract = true;
              this.setResetTimer(90);
            }
          })

          .catch((error: any) => {
            this.displayMessage(error, true);
          });
      } else {
        this.displayMessage('Forkert redirecttype!', true);
      }
    }
  }

  async doReturn() {
    if (this.popup) {
      window.close();
    } else {
      const arParms = await firstValueFrom(this.route.params.pipe());
      if (!this.utility.isEmpty(arParms['forsyning'])) {
        this.router.navigate([`${arParms['forsyning']}/home`]);
      } else {
        this.router.navigate(['home']);
      }
    }
  }

  async tryAgain() {
    this.dialogType = RedirectType.assignIdpUser;
    this.showMessage = false;
    this.showUserCredentials = true;
    this.router.navigate([], {queryParams: {}});
  }

  onChooseRemove(location: LocationRemove, event: any) {
    if (event.target.checked) {
      this.selectedContract.push(location);
    } else {
      this.selectedContract.splice(this.selectedContract.indexOf(location), 1);
    }
  }

  async onProcessContract(service: string) {
    let cleanuser = false;
    if (this.dialogType == RedirectType.removeContract) {
      cleanuser = true;
    } else {
      if (!this.username || !this.password) {
        return;
      }
      cleanuser = await this.callBackSvc
        .cleanUser(this.username, this.password)
        .catch(() => {
          this.displayMessage('Fejl under søgning på bruger.', true);
          return;
        })
        .then((value: boolean | void) => {
          if (value === false) {
            this.displayMessage('Brugeren findes ikke / Er tilknyttet anden loginmetode.', true);
            return false;
          }
          if (value === undefined) {
            return false;
          }
          return value;
        });
    }

    if (cleanuser) {
      const arParms = await firstValueFrom(this.route.params.pipe());
      if (this.password && this.username) {
        localStorage.setItem('acUsername', this.username);
        localStorage.setItem('acPassword', this.password);
      }
      localStorage.setItem('acService', service);
      localStorage.setItem('prefix', arParms['forsyning']);

      const idpBroker = await this.callBackSvc
        .getIdpBroker(arParms['forsyning'], service, this.dialogType)
        .catch((error) => {
          console.log('Error:', error);
          this.clearLocalStore();
          this.displayMessage('Fejl ved kontakt til id-udbyder', true);
          return;
        })
        .then((value: any) => {
          if (value === undefined || this.utility.isEmpty(value.url)) {
            return '';
          }
          return value;
        });

      if (!this.utility.isEmpty(idpBroker.url)) {
        window.open(idpBroker.url, '_self');
      } else {
        this.displayMessage('Fejl ved kontakt til id-udbyder', true);
      }
    }
  }

  async onRemoveContracts() {
    const confirm: Confirm = new Confirm();
    confirm.header = 'Tilknytning';
    confirm.message = 'Ophæv tilknytning?';
    confirm.confirmOptions = [
      { text: 'Ja', value: true } as ConfirmOption,
      { text: 'Nej', value: false } as ConfirmOption,
    ];

    if ((await this.modalService.confirm(confirm)) == true) {
      await this.callBackSvc
        .removeContracts(this.selectedContract)
        .catch((error) => {
          this.showRemoveContract = false;
          console.log('Error:', error);
          this.clearLocalStore();
          this.displayMessage('Fejl ved kontakt til id-udbyder', true);
        })
        .then(() => {
          this.showRemoveContract = false;
          this.displayMessage('Ophæv tilknyning(er) er fuldført.', false);
        });
    }
  }

  displayMessage(message: any, displayerror: boolean) {
    this.showUserCredentials = false;
    this.showMessage = true;
    this.textMessage = message;
    this.isErrorMessage = displayerror;
    // disable all displaytypes.
    this.dialogType = RedirectType.message;

    if (this.isErrorMessage) {
      if (!this.utility.isEmpty(message.error)) {
        this.textMessage = message.error;
      }

      if (!this.utility.isEmpty(message.status)) {
        this.textMessage += ` [${message.status}]`;
      }
    }
  }

  clearLocalStore() {
    localStorage.removeItem('acPopup');
    localStorage.removeItem('acUsername');
    localStorage.removeItem('acPassword');
    localStorage.removeItem('acService');
    localStorage.removeItem('prefix');
  }
}
