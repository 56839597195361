<section id="content" class="content">
    <div class="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
        <div *ngIf="showMessage" class="content__wrap">
            <div class="card shadow-lg">
              <div class="card-body">
                <div [ngClass]="isErrorMessage ? 'alert alert-danger' : 'alert alert-success'" role="alert">
                  <h1 class="h3">
                    @if (isErrorMessage && dialogType === redirectType.assignIdpUser) {
                    <div>
                      <i class="fa-solid fa-circle-x"></i>
                      Fejl under tilknytning
                    </div>
                    }
                    @if (isErrorMessage && dialogType === redirectType.addContract) {
                    <div>
                      <i class="fa-solid fa-circle-x"></i>
                      Fejl under tilføjelse
                    </div>
                    }
                    @if (!isErrorMessage) {
                    <div>
                      <i class="fa-solid fa-circle-check"></i>
                      {{ textMessage }}
                    </div>
                    }
                  </h1>
                </div>
                @if (isErrorMessage) {
                <div class="text-center">
                  <p>{{ textMessage }}</p>
                </div>
                }

                <div class="d-grid">
                  @if (isGeneralError) {
                  <button class="btn btn-primary btn-lg" (click)="tryAgain()">Prøv igen</button>
                  } @else {
                  <button class="btn btn-primary btn-lg" (click)="doReturn()">Tilbage</button>
                  }
                </div>
              </div>
            </div>
        </div>
        <div *ngIf="showUserCredentials" class="card shadow-lg">
            <div class="card-body" [hidden]="dialogType === redirectType.removeContract">
                <div class="text-center">
                    <h1 *ngIf="dialogType === redirectType.assignIdpUser" class="h3">Tilknyt forbrugssted</h1>
                    <h1 *ngIf="dialogType === redirectType.addContract" class="h3">Tilføj forbrugssted</h1>
                    <h1 *ngIf="dialogType === redirectType.removeContract" class="h3">Ophæv MitId tilknytning</h1>
                    <p [hidden]="dialogType === redirectType.removeContract">Indtast brugernavn og kodeord</p>
                </div>

                <form class="mt-4" action="index.html">
                    <div [hidden]="dialogType === redirectType.removeContract" class="mb-3">
                        <input name="brugernavn" [(ngModel)]="username" type="text" class="form-control" placeholder="brugernavn (0000-0000000)" autofocus>
                    </div>

                    <div [hidden]="dialogType === redirectType.removeContract" class="mb-3">
                        <input name="password" [(ngModel)]="password" type="password" class="form-control" placeholder="Adgangskode">
                    </div>

                    <div class="d-grid">
                        <div class="d-grid mt-3">
                            <button class="btn btn-primary btn-lg" (click)="onProcessContract('mitid')">MitID</button>
                        </div>
                        <button class="mt-3 btn btn-primary buttom-0 endt-0" (click)="doReturn()">Tilbage</button>
                    </div>
                </form>
          </div>
        </div>
        <div *ngIf="showRemoveContract" class="card shadow-lg">
              <div class="card-body">
                   <div class="text-center">
                        <h1 class="h3">Vælg en eller flere forsyninger</h1>
                    </div>
                    <table class="table table-hover" style="max-height: 400px; overflow-y: auto;">
                        <tbody>
                                <tr *ngFor="let location of locationRemove; index as idx" style="cursor: pointer;">
                                <td>
                                    <input type="checkbox" (click)="onChooseRemove(location, $event)">&nbsp;&nbsp;<label>{{ location.utilityName}}:</label>
                                    <br>
                                    <span *ngFor="let places of location.locations">
                                        - {{ places.description }}<br>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                     </table>
                     <div class="d-grid">
                        <div class="d-grid mt-3">
                            <button class="btn btn-primary btn-lg" [disabled]="selectedContract.length <= 0" (click)="onRemoveContracts()">Afmeld</button>
                        </div>
                        <button class="mt-3 btn btn-primary buttom-0 endt-0" (click)="doReturn()">Tilbage</button>
                    </div>
                </div>

            </div>
        </div>
</section>
